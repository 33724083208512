import React, { useReducer, createContext } from "react";
const styleMode = window.localStorage.getItem("styleMode");

const initialState = {
  userEmail: null,
  styleMode: styleMode ? styleMode : "dark",
};
export const UserContext = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case "userEmail":
      return { ...state, userEmail: action.payload };
    case "name":
      return { ...state, name: action.payload };
    case "pNo":
      return { ...state, pNo: action.payload };
    case "styleMode":
      return { ...state, styleMode: action.payload };
    case "signOut":
      return { ...initialState };
    default:
      return state;
  }
};

export const UserProvider = props => {
  const [userState, userDispatch] = useReducer(reducer, initialState);
  return (
    <UserContext.Provider value={{ userState, userDispatch }}>
      {props.children}
    </UserContext.Provider>
  );
};
