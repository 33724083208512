import React, { useState, useContext, useEffect } from "react"
import { H1, H2, BodyWrapper, P, CenteredDiv, Spinner, Button } from "../components"
import { UserContext } from "../contexts/userContext"
import firebase from "../firebase.js"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Switch from "@material-ui/core/Switch"
import RefreshIcon from "@material-ui/icons/Refresh"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import CircularProgress from "@material-ui/core/CircularProgress"
import TextField from '@material-ui/core/TextField'

import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"

// setAggregateItems(listCart)
// setDisplayAggregate(true)

const PendingOrders = () => {
  const { userState } = useContext(UserContext)

  const [pendingOrderList, setPendingOrderList] = useState([])
  const [displayCart, setDisplayCart] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [starttime, setStartTime] = useState('')
  const [endtime, setEndTime] = useState('')
  const [LeaderId, setLeaderID] = useState("")
  const [LeaderName, setLeaderName] = useState("")
  const [displayAggregate, setDisplayAggregate] = useState(false)
  const [aggregate, setAggregate] = useState([])
  const [aggregateItems, setAggregateItems] = useState([])
  const [cartID, setCartID] = useState("")
  const [refresh, setRefresh] = useState(0)
  const [totalDeliveries, setTotalDeliveries] = useState(0)
  const [grandTotal, setGrandTotal] = useState("")
  const [cartItems, setCartItems] = useState([])
  const db = firebase.firestore()
  const realtime = firebase.database()

  const MyLoader = {
    margin: 0,
    top: "auto",
    right: "auto",
    bottom: "50%",
    left: "auto",
    position: "fixed",
    zIndex: 1
  }

  const date = new Date()

  const cutOff = date.getFullYear() + "-" + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + " T 07:00"

  useEffect(() => {

    const fetchOrderData = async () => {
      setPageLoading(false)
      const date = new Date()

      // const cutOff = date.getFullYear() +
      //   '-' +
      //   ((date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1)) +
      //   '-' +
      //   (date.getDate() > 9 ? (date.getDate()) : '0' + (date.getDate())) + " T 07:00"

      const data = await db
        .collection("Orders")
        .where("Delivered", "==", false)
        .where('DateOfOrder', '>=', starttime)
        .where('DateOfOrder', '<', endtime)
        .where("Hold", "==", false)
        .where("Cancelled", "==", false)
        .get()

      const pendingList = []

      let count = 0

      if (data.empty) {

        console.log("YOWWWWWWWWWWWOOOO")
        setPageLoading(false)
        setLoading(false)
      } else {

        console.log("GGAI")

        data.forEach((doc) => {
          const { CustomerID, CustomerName, CartID, CustomerPNo, DeliveryAddress, LeaderID, LeaderName, TotalPrice, DateOfOrder, Delivered, POD, Return } = doc.data()

          // console.log(doc.id)

          if (pendingList[LeaderID] === undefined) {
            pendingList[LeaderID] = {
              Name: LeaderName,
              Qty: 1,
              IDs: [{ OrderID: doc.id, CartID: CartID }]
            }
            count = count + 1
          } else {
            pendingList[LeaderID].Qty = pendingList[LeaderID].Qty + 1

            pendingList[LeaderID].IDs.push({ OrderID: doc.id, CartID: CartID })
          }
        })

        fetchAggregateLeaders(pendingList)
        setTotalDeliveries(count)
        setPageLoading(false)
      }
    }

    const fetchAggregateLeaders = (list) => {
      const listLeaders = []

      console.log(list)

      for (var key in list) {
        listLeaders.push({
          key: key,
          name: list[key].Name,
          qty: list[key].Qty,
          IDs: list[key].IDs
        })
      }

      setPendingOrderList(listLeaders)
    }

    if (starttime === "" || endtime === "") {

      setPageLoading(false)

    } else {
      fetchOrderData()
    }

  }, [refresh])

  const handleDispatchAllOrders = (e, leaderID) => {
    e.preventDefault()

    const url = "https://api.tushop.io/api/v1/dispatchToCL"

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        LeaderID: leaderID,
        starttime,
        endtime
      })
    })
      .then((response) => {
        if (response.status === 200 || response.status === 500) {
          return response.json()
        } else {
          alert(response.error)
          throw new Error("Something went wrong on api server!")
        }
      })
      .then((response) => {
        alert(response.message)
        setRefresh(++refresh)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleFailedDelivery = (e, leaderID) => {
    e.preventDefault()

    const url = "https://api.tushop.io/api/v1/failedDelivery"

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        LeaderID: leaderID,
        starttime,
        endtime
      })
    })
      .then((response) => {
        if (response.status === 200 || response.status === 500) {
          return response.json()
        } else {
          alert(response.error)
          throw new Error("Something went wrong on api server!")
        }
      })
      .then((response) => {
        alert(response.message)
        setRefresh(++refresh)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleHoldOrder = (orderID, all) => {
    const yesterdate = new Date()

    yesterdate.setDate(yesterdate.getDate() - 1)

    const yesterday = yesterdate.getFullYear() + "-" + (yesterdate.getMonth() + 1 > 9 ? yesterdate.getMonth() + 1 : "0" + (yesterdate.getMonth() + 1)) + "-" + (yesterdate.getDate() > 9 ? yesterdate.getDate() : "0" + yesterdate.getDate()) + " T " + (yesterdate.getHours() > 9 ? yesterdate.getHours() : "0" + yesterdate.getHours()) + ":" + (yesterdate.getMinutes() > 9 ? yesterdate.getMinutes() : "0" + yesterdate.getMinutes())

    db.collection("Ledger")
      .where("OrderID", "==", orderID)
      .get()
      .then((data) => {
        if (!data.empty) {
          data.forEach((doc) => {
            db.collection("Ledger")
              .doc(doc.id)
              .update({
                Hold: true,
                Date: yesterday
              })
          })
        } else {
          alert("Error: Ledger not found!")
        }
      })
      .then(() => {
        db.collection("Orders")
          .doc(orderID)
          .update({
            Hold: true
          })
          .then(() => {
            console.log("Document successfully updated!")
          })
          .then(() => {
            alert(all + " Ledger(s) put on hold")
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error)
          })
      })
  }

  const handleHoldAllPOD = (e, leaderID) => {
    const date = new Date()

    date.setDate(date.getDate() - 1)

    const yesterday2300 = date.getFullYear() + "-" + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + " T 22:00"

    db.collection("Orders")
      .where("LeaderID", "==", leaderID)
      .where("DateOfOrder", "<=", cutOff)
      .where("POD", "==", true)
      .where("Delivered", "==", false)
      .get()
      .then((data) => {
        if (data.empty) {
          alert("No active POD Orders")
        } else {
          data.forEach((doc) => {
            console.log("doc.id ", doc.id)

            handleHoldOrder(doc.id, data.size)
          })
        }
      })
  }

  const handleConfirmAllOrderDeliver = (e, leaderID) => {
    const date = new Date()

    date.setDate(date.getDate() - 1)

    const yesterday2300 = date.getFullYear() + "-" + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + " T 22:00"

    db.collection("Orders")
      .where("LeaderID", "==", leaderID)
      .where('DateOfOrder', '>=', starttime)
      .where('DateOfOrder', '<', endtime)
      .where("Delivered", "==", false)
      .get()
      .then((data) => {
        data.forEach((doc) => {
          handleDeliverOrder(e, doc.id)
        })
      })
      .then(() => {
        alert("Orders Delivered")

        setRefresh(refresh + 1)
      })
  }
  const handleDeliverAllOrders = (e, leaderID) => {
    confirmAlert({
      title: `Deliver status for ${leaderID}`,
      message: "Are you sure you want to perfom this function ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleConfirmAllOrderDeliver(e, leaderID)
        },
        {
          label: "No",
          onClick: null
        }
      ]
    })
  }

  const handleDeliverOrder = (e, orderID) => {
    e.preventDefault()
    console.log("hellooooo")

    const url = "https://api.tushop.io/api/v1/deliverOrder"

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        orderID: orderID,
        checked: true
      })
    })
      .then((response) => {
        console.log("response.json()", response.json())
        if (response.status === 201) {
          console.log("YOOWWWWWW")

          return response.json()
        } else {
          alert(response.message)
          throw new Error("Something went wrong on api server!")
        }
      })
      .then((response) => {
        console.log("OLAAA")
        console.debug(response)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleFillLeaderAggregate = (e, leaderID, leaderName, data) => {
    console.log(data)

    setLoading(true)

    setLeaderID(leaderID)
    setLeaderName(leaderName)

    const fetchData = async () => {
      const aggregateList = []

      data.forEach((doc) => {
        const CartID = doc.CartID

        const shoppingCartRef = firebase.database().ref("shoppingCarts/" + CartID)
        shoppingCartRef.on("value", (snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach(function (childSnapshot) {
              if (childSnapshot.key !== "grandTotal") {
                const data = childSnapshot.val()

                if (aggregateList[childSnapshot.key] === undefined) {
                  console.log("Ohaa", data.quantity)
                  aggregateList[childSnapshot.key] = parseInt(data.quantity)
                } else {
                  console.log("Ohaa2", aggregateList[childSnapshot.key])
                  aggregateList[childSnapshot.key] = aggregateList[childSnapshot.key] + parseInt(data.quantity)
                }
              }
            })

            // Code for adding grandtotal to cart record
          } else {
            console.log("No data available")
            // this.setState({
            //   emptyCart: true
            // })
          }
        })
      })

      setTimeout(() => {
        console.log("MHMMM")
        console.log("LIST2! ", aggregateList)
        setAggregate(aggregateList)
      }, 3000)

      setTimeout(() => {
        handleDisplayAggregate(aggregateList)
      }, 5000)
    }
    fetchData()
  }

  const handleDisplayAggregate = (list) => {
    console.log("AGGREGATE!", list)

    const listCart = []

    const fetchAggregateData = async () => {
      for (var key in list) {
        const quantity = list[key]

        console.log(key, "OHAA")

        await db
          .collection("Products")
          .doc(key)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const { Description } = doc.data()

              listCart.push({
                Description: Description,
                Qty: quantity
              })

              console.log("PUSHED!")
            }
          })
          .then(() => {
            console.log("1", listCart)
            console.log("SET!")
          })
      }

      setAggregateItems(listCart)
      setDisplayAggregate(true)
      setLoading(false)
      console.log("LASTLY!")
      console.log(listCart)
    }

    fetchAggregateData()
  }

  const handleCloseCart = () => {
    setDisplayCart(false)
    setCartID("")
    setCartItems([])
    setGrandTotal("")
  }

  const handleCloseAggregate = () => {
    setDisplayAggregate(false)
    setLeaderID("")
    setLeaderName("")
  }

  const handleRefresh = () => {
    setRefresh(refresh + 1)
  }

  const DialogAggregate = () => {
    return (
      <Dialog open={displayAggregate} onClose={handleCloseAggregate} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <H2>Aggregate : {LeaderName}</H2>
        </DialogTitle>
        <DialogContent>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow
                style={{
                  height: "5px",
                  width: "100%",
                  backgroundColor: "#d3d3d3"
                }}
              >
                <TableCell>
                  <b>
                    <h2>Description</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Qty</h2>
                  </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {aggregateItems.map((data) => (
                <TableRow key={data.Description}>
                  <TableCell>{data.Description}</TableCell>
                  <TableCell>{data.Qty}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAggregate}>Cancel</Button>
        </DialogActions>
      </Dialog>
    )
  }

  const loader = () => {
    if (loading) {
      return (
        <CenteredDiv vertical>
          <Spinner large secondary style={MyLoader} />
        </CenteredDiv>
      )
    }
  }

  const DialogCart = () => {
    return (
      <Dialog open={displayCart} onClose={handleCloseCart} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">CartID: {cartID}</DialogTitle>

        <DialogContent>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow
                style={{
                  height: "5px",
                  width: "100%",
                  backgroundColor: "#d3d3d3"
                }}
              >
                <TableCell>
                  <b>
                    <h2>Description</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Qty</h2>
                  </b>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <b>
                    <h2>Price</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Total</h2>
                  </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cartItems.map((data) => (
                <TableRow key={data.key}>
                  <TableCell>{data.desc}</TableCell>
                  <TableCell>{data.quantity}</TableCell>
                  <TableCell>X</TableCell>
                  <TableCell>{data.price}</TableCell>
                  <TableCell>
                    <b>{data.total}</b>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow key="row2">
                <TableCell colSpan={3}>
                  <h1>GrandTotal</h1>
                </TableCell>
                <TableCell colSpan={2}>
                  <h1>{grandTotal}</h1>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCart} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const pendingOrders = () => {

    const handleChange = (e: any) => {
      const date = new Date(e.target.value)

      const today =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1)) +
        '-' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
        ' T ' +
        (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
        ':' +
        (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

      if (e.target.name === 'starttime') {

        setStartTime(today)

        console.log('OYAA', today)
      } else if (e.target.name === 'endtime') {

        setEndTime(today)
      }
    }

    return (
      <BodyWrapper>
        {DialogCart()}
        {DialogAggregate()}
        {loader()}
        <H1>
          <b>Outbound Deliveries:</b> {totalDeliveries}
        </H1>
        {/* <Button onClick={handleRefresh} style={{ display: "inline-flex", width: "15%" }}>
          <RefreshIcon />
        </Button> */}
        <TextField
          id="datetime-local"
          onChange={handleChange}
          name="starttime"
          label="Start Date/Time"
          fullWidth
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <span style={{ flexGrow: 2 }} />
        <TextField
          id="datetime-local"
          onChange={handleChange}
          name="endtime"
          label="End Date/Time"
          fullWidth
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <span style={{ flexGrow: 1 }} />
        <Button
          onClick={handleRefresh}
        >
          FILTER
        </Button>
        <TableContainer component={Paper} style={{ maxHeight: 500 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow style={{ background: "#fe7c19" }}>
                <TableCell>
                  <P>Leader ID</P>
                </TableCell>
                <TableCell>
                  <P>Leader Name</P>
                </TableCell>
                <TableCell>
                  <P>No. of orders</P>
                </TableCell>
                <TableCell>
                  <P>Failed delivery</P>
                </TableCell>

                <TableCell>
                  <P>Dispatched?</P>
                </TableCell>
                <TableCell>
                  <P>Deliver all orders?</P>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageLoading ? (
                <TableRow>
                  <TableCell colSpan={6} style={{ textAlign: "center" }}>
                    <CircularProgress color="#fe7c19" size={40} />
                  </TableCell>
                </TableRow>
              ) : (
                pendingOrderList.map((data) => (
                  <TableRow key={data.key}>
                    <TableCell>
                      <Button onClick={(e) => handleFillLeaderAggregate(e, data.key, data.name, data.IDs)}>{data.key}</Button>
                    </TableCell>
                    <TableCell>{data.name}</TableCell>
                    <TableCell>{data.qty}</TableCell>
                    <TableCell>
                      <Button onClick={(e) => handleFailedDelivery(e, data.key)}>FAILED DELIVERY</Button>
                    </TableCell>
                    <TableCell>
                      <Button onClick={(e) => handleDispatchAllOrders(e, data.key)}>DISPATCH</Button>
                    </TableCell>
                    <TableCell>
                      <Button onClick={(e) => handleDeliverAllOrders(e, data.key)}>DELIVER</Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </BodyWrapper>
    )
  }
  return pendingOrders()
}

export default PendingOrders
