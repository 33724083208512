import React, { useState, useContext, useEffect } from "react";
import { H1, BodyWrapper, P } from "../components";
import { UserContext } from "../contexts/userContext";
import firebase from "../firebase.js";
import "firebase/firestore";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const MyCustomers = () => {
  const [customerList, setCustomerList] = useState([]);
  const { userState } = useContext(UserContext);
  const db = firebase.firestore();

  useEffect(() => {
    const fetchCustomerData = async () => {
      const data = await db.collection("Customers").where("LeaderID", "==", userState.userId).get()
      
      const list = []

      data.forEach(doc => {
        const { Address, City, Email, Name, Neighborhood, PhoneNumber } = doc.data();

        list.push({
          UID: doc.id,
          Name: Name,
          Address: Address,
          Neighborhood: Neighborhood,
          City: City,
          PhoneNumber: PhoneNumber,
          Email: Email
        })
      })

      setCustomerList(list)

    }
    
    fetchCustomerData();
  }, []);

  const myCustomers = () => {
    return (
      <BodyWrapper>
        <H1><b>My Customers:</b> {customerList.length}</H1>

        <TableContainer component={Paper}>
                        <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow style={{background: "#fe7c19"}}>
                  <TableCell><P>UID</P></TableCell>
                  <TableCell><P>Name</P></TableCell>
                  <TableCell><P>Address</P></TableCell>
                  <TableCell><P>Neighborhood</P></TableCell>
                  <TableCell><P>City</P></TableCell>
                  <TableCell><P>Phone Number</P></TableCell>
                  <TableCell><P>Email</P></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {customerList.map(data =>
                <TableRow key={data.UID}>
                  <TableCell>{data.UID}</TableCell>
                  <TableCell>{data.Name}</TableCell>
                  <TableCell>{data.Address}</TableCell>
                  <TableCell>{data.Neighborhood}</TableCell>
                  <TableCell>{data.City}</TableCell>
                  <TableCell>{data.PhoneNumber}</TableCell>
                  <TableCell>{data.Email}</TableCell>
                </TableRow>
              )}
              </TableBody>
            </Table>
          </TableContainer>
       
        
      </BodyWrapper>
    );
  };
  return myCustomers()

};

export default MyCustomers;
