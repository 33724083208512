import * as firebase from "firebase/app";
import "firebase/messaging";
import "firebase/auth";
import 'firebase/storage';
import "firebase/functions";
import "firebase/firestore";
import "firebase/database";

const config = {
  apiKey: "AIzaSyCh2TKYhCJTtd1ySvvhJPu7zkURp4mrthE",
  authDomain: "tushop-shopping-app.firebaseapp.com",
  databaseURL: "https://tushop-shopping-app-default-rtdb.firebaseio.com",
  projectId: "tushop-shopping-app",
  storageBucket: "tushop-shopping-app.appspot.com",
  messagingSenderId: "241381709741",
  appId: "1:241381709741:web:52b715fe7985134c793324",
  measurementId: "G-7EBLKYDZYG"
};
firebase.initializeApp(config);

const storage = firebase.storage().ref();

// if ("Notification" in window) {
//   const messaging = firebase.messaging();
//   messaging.usePublicVapidKey("BI98i98uFRheahYFlZbJ_0sVMBdyQPbcblwMvV0zzGE4ElAMMAJB6Y-scIMHKgcUT36atUrbwvp2wAyyKlUZhJ8");

//   messaging.onMessage(payload => {
//     console.log("Message received. ", payload);
//     // push message to UI
//   });

//   messaging.onTokenRefresh(() => {
//     const db = firebase.firestore();
//     messaging
//       .getToken()
//       .then(refreshedToken => {
//         db.collection("users")
//           .doc(firebase.auth().currentUser.uid)
//           .update({ pushTokenWeb: refreshedToken })
//           .then(() => {
//             console.log("Token updated.");
//           })
//           .catch(err => console.log(err));
//       })
//       .catch(err => {
//         console.log("Unable to retrieve refreshed token ", err);
//       });
//   });
// }

export {  storage };

export default firebase;
