import React, { useState, useContext, useEffect } from "react";
import { H1, BodyWrapper, CenteredDiv, Spinner, Button, P } from "../components";
import { UserContext } from "../contexts/userContext";
import firebase from "../firebase.js";
import RefreshIcon from '@material-ui/icons/Refresh';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { firestore } from "firebase";



const PendingOrders = () => {

  const { userState } = useContext(UserContext);

  const [pendingOrderList, setPendingOrderList] = useState([]);
  const [displayCart, setDisplayCart] = useState(false)
  const [loading, setLoading] = useState(false)
  const [cartID, setCartID] = useState("")
  const [yesterday, setYesterday] = useState("")
  const [refresh, setRefresh] = useState(0)
  const [grandTotal, setGrandTotal] = useState("")
  const [cartItems, setCartItems] = useState([]);
  const [aggregate, setAggregate] = useState([])
  const db = firebase.firestore();
  const realtime = firebase.database();

  useEffect(() => {

    setLoading(true)
    const fetchOrderData = async () => {
      const data = await db.collection("Orders").orderBy("DateOfOrder", "asc").get()
      
      const cartList = []

      const aggregateList = []

      const date = new Date();

      const today = date.getFullYear()+
                    '-'+
                    ((date.getMonth()+1)>9?(date.getMonth()+1):'0'+(date.getMonth()+1))+
                    '-'+
                    (date.getDate()>9?(date.getDate()):'0'+(date.getDate()))

      setYesterday(today)

      var itemsProcessed = 0;

      data.forEach((doc) => {
        const { CustomerID,  CustomerName, CartID, CustomerPNo, DeliveryAddress, LeaderID, LeaderName, TotalPrice, DateOfOrder, CDelivery, POD, Return, CLConfirm} = doc.data();

          if(!CLConfirm && LeaderID === userState.userId && DateOfOrder <= today){   

              const shoppingCartRef = firebase.database().ref('shoppingCarts/' + CartID);
              shoppingCartRef.on('value', (snapshot) => {

                if (snapshot.exists()) {

                  snapshot.forEach(function(childSnapshot) {

                    if(childSnapshot.key !== "grandTotal"){
                      const data = childSnapshot.val();

                      cartList.push({
                        OrderID: doc.id,
                        customerID: CustomerID,  
                        customerName: CustomerName, 
                        cartID: CartID, 
                        pNo: CustomerPNo, 
                        address: DeliveryAddress, 
                        leaderID: LeaderID, 
                        leaderName: LeaderName,
                        productDesc: data.productDesc,
                        productPrice: data.productPrice,
                        quantity: data.quantity,
                        total: data.total,  
                        return: Return? "True" : "False",
                        status: POD? "POD": "Paid",
                        CLConfirm: CLConfirm,
                        DateOfOrder: DateOfOrder  
                      });

                      if(aggregateList[childSnapshot.key] === undefined){
                        console.log("Ohaa", data.quantity)
                        aggregateList[childSnapshot.key] = parseInt(data.quantity)
                      }else{
                        console.log("Ohaa2", aggregateList[childSnapshot.key])
                        aggregateList[childSnapshot.key] = aggregateList[childSnapshot.key] + parseInt(data.quantity)
                      }
                    }

                  })                  

                  // Code for adding grandtotal to cart record


                } else {
                  console.log("No data available");
                  // this.setState({
                  //   emptyCart: true
                  // })
                }
              
              });
              
          }


      })

      setTimeout(() => {
        console.log("MHMMM")
        setLoading(false)
        setAggregate(aggregateList)
        setPendingOrderList(cartList)
      }, 3000);

      console.log("PIMBI", aggregate)



    }
    
    fetchOrderData();
  }, [refresh]);

  const handleChangeActivity = (e) => {

    e.preventDefault()

    setLoading(true)

    const OrderRef = db.collection("Orders").doc(e.target.name);

    OrderRef.update({
      CLConfirm: e.target.checked,
      CDelivery: false
    })
    .then(() => {
      console.log("Document successfully updated!");
      setRefresh(refresh +1)
    })
    .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    });
  }

  const handleDisplayCart  = () =>  {

    console.log("AGGREGATE!", aggregate)

    const listCart = []

    setLoading(true)

    

    const fetchData = async () => {

      for (var key in aggregate) {
      
        const quantity = aggregate[key]
  
        console.log(key, "OHAA")
  
        await db.collection("Products").doc(key).get().then(doc => {
          if(doc.exists){
            const { Description } = doc.data();
  
            listCart.push({
              Description : Description,
              Qty : quantity
            })
  
            console.log("PUSHED!")
          }
        }).then(() => {
  
          console.log("1",listCart)
          console.log("SET!")
        })
      };

      setCartItems(listCart)
      setDisplayCart(true)
      setLoading(false)
      console.log("LASTLY!")
    }

    fetchData()
    
  }

  const handleCloseCart = () => {

    setDisplayCart(false)
    setCartID("")
    setCartItems([])
    setGrandTotal("")

  }

  const DialogCart = () => {
    return(
      <Dialog open={displayCart} onClose={handleCloseCart} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Aggregate</DialogTitle>

          <DialogContent>
          <Table aria-label="simple table" >
          <TableHead>
            <TableRow style={{height: "5px", width: "100%", backgroundColor: "#d3d3d3"}}>
              <TableCell><b><h2>Description</h2></b></TableCell>
              <TableCell><b><h2>Qty</h2></b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {cartItems.map(data =>
            <TableRow key={data.Description}>
              <TableCell>{data.Description}</TableCell>
              <TableCell>{data.Qty}</TableCell>
            </TableRow>
          )}
          </TableBody>
        </Table> 
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCart}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

    )
  }

  const pendingOrders = () => {

    const handleRefresh = () => {
      setRefresh(refresh+1)
    }

    const loader = {
      margin: 0,
      top: 'auto',
      right: 'auto',
      bottom: "50%",
      left: 'auto',
      position: 'fixed',
      zIndex: 1
    }

    return (
      <BodyWrapper>
        {DialogCart()}
        {loading?
          <CenteredDiv vertical>
            <Spinner large secondary style={loader}/>
            <P style={loader}>Please wait...</P>
          </CenteredDiv>
        :
          <>
          </>
        }
        <H1><b>Incoming Deliveries:</b> {pendingOrderList.length}</H1>
        <Button onClick={handleDisplayCart} style={{display: "inline-flex", width: "15%"}}><FormatListNumberedRtlIcon/></Button>
        <Button onClick={handleRefresh} style={{display: "inline-flex", width: "15%"}}><RefreshIcon/></Button>
        <hr/>
        <TableContainer component={Paper}>
                        <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow style={{background: "#fe7c19"}}>
                  <TableCell><P>Order ID</P></TableCell>
                  <TableCell><P>Customer ID</P></TableCell>
                  <TableCell><P>Customer Name</P></TableCell>
                  <TableCell><P>Product Desc</P></TableCell>
                  <TableCell><P>Price</P></TableCell>
                  <TableCell><P>Quantity</P></TableCell>
                  <TableCell><P>Total</P></TableCell>
                  <TableCell><P>Return?</P></TableCell>
                  <TableCell><P>Status</P></TableCell>
                  <TableCell><P>Date</P></TableCell>
                  <TableCell><P>Confirm?</P></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {pendingOrderList.map(data =>
                <TableRow key={data.OrderID+data.productDesc}>
                  <TableCell>{data.OrderID}</TableCell>
                  <TableCell>{data.customerID}</TableCell>
                  <TableCell>{data.customerName}</TableCell>
                  <TableCell>{data.productDesc}</TableCell>
                  <TableCell>{data.productPrice}</TableCell>
                  <TableCell>{data.quantity}</TableCell>
                  <TableCell>{data.total}</TableCell>
                  <TableCell>{data.return}</TableCell>
                  <TableCell> {data.status? "POD": "Paid"}</TableCell>
                  <TableCell>{data.DateOfOrder}</TableCell>
                  <TableCell>
                    <Switch
                      checked={data.CLConfirm}
                      onChange={e => handleChangeActivity(e)}
                      name={data.OrderID}
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              )}
              </TableBody>
            </Table>
          </TableContainer>    
      </BodyWrapper>
    );
  };
  return pendingOrders()

};

export default PendingOrders;