import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Lander from "./containers/Lander";
import SignIn from "./containers/SignIn";
import VerifyOTP from "./containers/verifyOTP";
import Dashboard from "./containers/Dashboard";
import IncomingItems from "./containers/IncomingItems";
import PendingOrders from "./containers/PendingOrders";
import CompletedOrders from "./containers/CompletedOrders";
import OutboundDeliveries from "./containers/OutboundDeliveries";
import MyCustomers from "./containers/MyCustomers";
import Confirmed from "./containers/Confirmed";
import Header from "./containers/Header";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import { UserContext } from "./contexts/userContext";
import {
  CenteredDiv,
  H1,
  Toast,
  Spinner,
  MobileMenuBar,
  BodyWrapper,
  P
} from "./components";
import styled, { ThemeProvider } from "styled-components";
import GlobalStyle from "./themes/GlobalStyle";
import "firebase/firestore";
import { colors } from "./themes";

const AppWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
`;

const MainRouter = () => {
  // eslint-disable-next-line
  const [initializationComplete, setInitComplete] = useState(true);
  const { userState } = useContext(UserContext);
  const userId = userState.userEmail;

  useEffect(() => {

    // sendPushNotification({
    //   token: userState.userData.pushTokenWeb,
    //   title: "Boop",
    //   body: "shoop"
    // });

    // firebase.auth().onAuthStateChanged(user => {
    //   if (!!user) {
    //     const uid = firebase.auth().currentUser.uid;
    //     db.collection("users")
    //       .doc(uid)
    //       .get()
    //       .then(res => {
    //         if (res.data() && res.data().firstName) {
    //           userDispatch(
    //             { type: "updateProfile", payload: res.data() },
    //             { type: "verifying", payload: false }
    //           );
    //         }
    //         userDispatch({ type: "userId", payload: uid });
    //         setInitComplete(true);
    //       });
    //   } else {
    //     userDispatch({
    //       type: "signOut"
    //     });
    //     setInitComplete(true);
    //   }
    // });
  }, []);

  const noMatch = () => {
    return (
      <BodyWrapper>
        <H1>Oops!</H1>
        <P>You're looking for a page that doesn't exist.</P>
      </BodyWrapper>
    );
  };

  const routeWithAuth = destination => {
    return !userId ? (
      <Redirect
        to={{
          pathname: "/signin"
        }}
      />
    ) : (
      destination
    );
  };

  const reRouteIfAuthenticated = destination => {
    return userId ? (
      <Redirect
        to={{
          pathname: "/dashboard"
        }}
      />
    ) : (
      destination
    );
  };

  const nestedSwitch = () => {
    return (
      <>
        {userId && <MobileMenuBar />}
        <Header />
        <Route
          render={({ location }) => (
            <TransitionGroup appear>
              <CSSTransition
                key={location.key}
                timeout={1000}
                classNames="fade"
              >
                <Switch location={location}>
                  <Route
                    exact
                    path={"/"}
                    render={() => reRouteIfAuthenticated(<SignIn />)}
                  />
                  <Route
                    exact
                    path={"/signin"}
                    render={() => reRouteIfAuthenticated(<SignIn />)}
                  />
                  <Route
                    path={"/verifyOTP"}
                    render={(props) => reRouteIfAuthenticated(<VerifyOTP {...props} />)}
                  />
                  <Route
                    path={"/dashboard"}
                    render={() => routeWithAuth(<Dashboard />)}
                  />
                  <Route
                    path={"/outboundDeliveries"}
                    render={() => routeWithAuth(<OutboundDeliveries />)}
                  />
                  <Route
                    path={"/incomingItems"}
                    render={() => routeWithAuth(<IncomingItems />)}
                  />
                  <Route
                    path={"/pendingOrders"}
                    render={() => routeWithAuth(<PendingOrders />)}
                  />
                  <Route
                    path={"/completedOrders"}
                    render={() => routeWithAuth(<CompletedOrders />)}
                  />
                  <Route
                    path={"/myCustomers"}
                    render={() => routeWithAuth(<MyCustomers />)}
                  />
                  <Route
                    path={"/privacy-policy"}
                    render={() => <PrivacyPolicy />}
                  />
                  <Route path="*" render={noMatch} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </>
    );
  };

  const router = () => {
    return (
      <Router>
        <>
          <Toast />
          <Switch>
            <Route path={"/confirmed"} render={() => <Confirmed />} />
            <Route path="*" render={nestedSwitch} />
          </Switch>
        </>
      </Router>
    );
  };

  const renderApp = () => {
    const app = !initializationComplete ? (
      <CenteredDiv vertical horizontal>
        <Spinner large secondary />
      </CenteredDiv>
    ) : (
      router()
    );
    return app;
  };

  const styleMode = window.localStorage.getItem("styleMode");
  return (
    <ThemeProvider
      theme={styleMode && styleMode === "dark" ? colors.dark : colors.main}
    >
      <>
        <GlobalStyle />
        <AppWrapper>{renderApp()}</AppWrapper>
      </>
    </ThemeProvider>
  );
};

export default MainRouter;
