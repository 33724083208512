import React, { useState, useContext } from "react";
import {
  P,
  H1,
  Button,
  Input,
  Form,
  BodyWrapper
} from "../components";
import { UserContext } from "../contexts/userContext";
import firebase from "../firebase.js";
import {
  // eslint-disable-next-line
  BrowserRouter as Router,
  Redirect
} from "react-router-dom";

const SignIn = () => {

  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [leaderId, setLeaderId] = useState("");
  const [leaderName, setLeaderName] = useState("");
  const [leaderPNO, setLeaderPNo] = useState("");
  const [proceedToOTP, setProceedToOTP] = useState(false);  

  const { userState, userDispatch } = useContext(UserContext);
  const db = firebase.firestore();

  const onClickSubmit = async () => {

    console.log("FULL NOOOOO!", phoneNumber)

    firebase.auth().signInWithEmailAndPassword(email, password).then((userCredential) => {
        // Signed in
        console.log(userCredential.user.email)

        if(userCredential.user.email === "logistics@tushop.io"){
          userDispatch({ type: "userEmail", payload: "logistics@tushop.io" })
        }else{
          console.log("Wrong Email")

          alert("Invalid Email. Please try again")
        }
        // ...
    }).catch((error) => {

        alert(error.message)

    });

  };

  const signin = () => {

    return (
      <>
        <BodyWrapper>
          <H1>Sign In</H1>
          <Form>
            <div>
              <Input
                onChange={e => setEmail(e.target.value)}
                name="UserName"
                type="email"
                placeholder="Email"
              />
              <br/>
              <Input
                onChange={e => setPassword(e.target.value)}
                name="UserName"
                type="password"
                placeholder="Password"
              />
            </div>
          </Form>
          <div>
              <Button marginBottom onClick={onClickSubmit}>
                Sign in
              </Button>
            </div>
        </BodyWrapper>
      </>
    );
  }


  // if(proceedToOTP){

  //   return <Redirect
  //                       push
  //                       to={{
  //                           pathname: "/dashboard",
  //                       }}
  //                   />
  // }

  if(proceedToOTP){

    return (<Redirect
                  to={{
                      pathname: "/verifyOTP",
                      state: {  phoneNumber: phoneNumber,
                                leaderId: leaderId,
                                leaderName: leaderName,
                                leaderPNO: leaderPNO
                      }
                  }}
            />
    )

  }else{
    return signin()
  }

};

export default SignIn;