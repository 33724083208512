import React, { useState, useContext, useEffect } from 'react'
import {
  H1,
  H2,
  BodyWrapper,
  P,
  CenteredDiv,
  Spinner,
  Button,
} from '../components'
import { UserContext } from '../contexts/userContext'
import firebase from '../firebase.js'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Switch from '@material-ui/core/Switch'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'

// setAggregateItems(listCart)
// setDisplayAggregate(true)

const PendingOrders = () => {
  const { userState } = useContext(UserContext)

  const [pendingOrderList, setPendingOrderList] = useState([])
  const [displayCart, setDisplayCart] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [yesterday, setYesterday] = useState('')
  const [LeaderId, setLeaderID] = useState('')
  const [LeaderName, setLeaderName] = useState('')
  const [displayAggregate, setDisplayAggregate] = useState(false)
  const [aggregate, setAggregate] = useState([])
  const [aggregateItems, setAggregateItems] = useState([])
  const [cartID, setCartID] = useState('')
  const [refresh, setRefresh] = useState(0)
  const [grandTotal, setGrandTotal] = useState('')
  const [cartItems, setCartItems] = useState([])
  const db = firebase.firestore()
  const realtime = firebase.database()

  const MyLoader = {
    margin: 0,
    top: 'auto',
    right: 'auto',
    bottom: '50%',
    left: 'auto',
    position: 'fixed',
    zIndex: 1,
  }
  useEffect(() => {
    const fetchOrderData = async () => {
      setPageLoading(true)
      const data = await db
        .collection('Orders')
        .where('Delivered', '==', true)
        .where('Cancelled', '==', false)
        .orderBy('DateOfOrder', 'desc')
        .limit(1000)
        .get()

      const pendingList = []

      data.forEach(doc => {
        const {
          CustomerID,
          CustomerName,
          CartID,
          CustomerPNo,
          DeliveryAddress,
          LeaderID,
          LeaderName,
          TotalPrice,
          DateOfOrder,
          Delivered,
          POD,
          Return,
          Hold,
        } = doc.data()

        pendingList.push({
          key: doc.id,
          customerID: CustomerID,
          customerName: CustomerName,
          cartID: CartID,
          pNo: CustomerPNo,
          address: DeliveryAddress,
          leaderID: LeaderID,
          leaderName: LeaderName,
          Total: TotalPrice,
          date: DateOfOrder,
          delivered: Delivered,
          hold: Hold,
          pod: POD,
          return: Return,
        })
      })

      setPendingOrderList(pendingList)
      setPageLoading(false)
    }

    fetchOrderData()
  }, [refresh])

  const handleChangeActivity = (e, orderID) => {
    const url = 'https://api.tushop.io/api/v1/deliverOrder'

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderID: orderID,
        checked: e.target.checked,
      }),
    })
      .then(response => {
        if (response.status === 201) {
          return response.json()
        } else {
          alert(response.message)
          throw new Error('Something went wrong on api server!')
        }
      })
      .then(response => {
        console.debug(response)
        alert(response.message)
        setRefresh(refresh + 1)
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleHoldOrder = (e, orderID) => {
    const url = 'https://api.tushop.io/api/v1/holdOrder'

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderID: orderID,
        checked: e.target.checked,
      }),
    })
      .then(response => {
        if (response.status === 201) {
          return response.json()
        } else {
          alert(response.message)
          throw new Error('Something went wrong on api server!')
        }
      })
      .then(response => {
        console.debug(response)
        alert(response.message)

        setRefresh(refresh + 1)
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleDisplayCart = (e, cartID, Total) => {
    const fetchData = async () => {
      const data = await db.collection('Products').get()

      const listCart = []

      data.forEach(doc => {
        const { Name, Description, ImgUrl, Price } = doc.data()

        if (cartID.charAt(0) === 'C') {
          const shoppingCartRef = realtime.ref(
            'shoppingCarts/' + cartID + '/' + doc.id
          )
          shoppingCartRef.on('value', snapshot => {
            const data = snapshot.val()

            if (snapshot.exists()) {
              listCart.push({
                key: doc.id,
                name: Name,
                desc: Description,
                imgUrl: ImgUrl,
                price: Price,
                quantity: data.quantity,
                total: data.total,
              })

              // setFormState({
              //   ...formState,
              //     displayCart: true!,
              //     cartItems: listCart,
              //     cartID: cartID,
              //     grandTotal: Total
              // })

              setCartItems(listCart)
              setGrandTotal(Total)
              setCartID(cartID)

              // Code for adding grandtotal to cart record
            } else {
              console.log('No data available')
              // this.setState({
              //   emptyCart: true
              // })
            }
          })
        } else if (cartID.charAt(0) === 'G') {
          db.collection('GBQueue')
            .doc(cartID)
            .get()
            .then(doc => {
              const { ProductID, Quantity } = doc.data()

              db.collection('Products')
                .doc(ProductID)
                .get()
                .then(doc2 => {
                  const { Description, Price } = doc2.data()

                  listCart.push({
                    key: doc.id,
                    desc: Description,
                    price: Price,
                    quantity: Quantity,
                    total: (parseInt(Price) * parseInt(Quantity)).toString(),
                  })

                  setCartItems(listCart)
                  setGrandTotal(Total)
                  setCartID(cartID)
                })
            })
        }
      })

      setDisplayCart(true)
    }
    fetchData()
  }

  const handleFillLeaderAggregate = (e, leaderID, leaderName) => {
    setLoading(true)

    setLeaderID(leaderID)
    setLeaderName(leaderName)

    const fetchData = async () => {
      const aggregateList = []

      const date = new Date()

      const today =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1)) +
        '-' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate())

      setYesterday(today)

      const data = await db
        .collection('Orders')
        .where('Delivered', '==', false)
        .where('LeaderID', '==', leaderID)
        .where('Cancelled', '==', false)
        .where('DateOfOrder', '<=', today)
        .orderBy('DateOfOrder', 'asc')
        .get()

      data.forEach(doc => {
        const { CartID } = doc.data()

        if (CartID.charAt(0) === 'C') {
          console.log('OYAC')

          const shoppingCartRef = firebase
            .database()
            .ref('shoppingCarts/' + CartID)
          shoppingCartRef.on('value', snapshot => {
            if (snapshot.exists()) {
              snapshot.forEach(function(childSnapshot) {
                if (childSnapshot.key !== 'grandTotal') {
                  const data = childSnapshot.val()

                  if (aggregateList[childSnapshot.key] === undefined) {
                    console.log('Ohaa', data.quantity)
                    aggregateList[childSnapshot.key] = parseInt(data.quantity)
                  } else {
                    console.log('Ohaa2', aggregateList[childSnapshot.key])
                    aggregateList[childSnapshot.key] =
                      aggregateList[childSnapshot.key] + parseInt(data.quantity)
                  }
                }
              })

              // Code for adding grandtotal to cart record
            } else {
              console.log('No data available')
              // this.setState({
              //   emptyCart: true
              // })
            }
          })
        } else if (CartID.charAt(0) === 'G') {
          console.log('OYAG')

          db.collection('GBQueue')
            .doc(CartID)
            .get()
            .then(doc => {
              const { GBID, Quantity } = doc.data()

              db.collection('GroupBuy')
                .doc(GBID)
                .get()
                .then(doc1 => {
                  const { ProductID } = doc1.data()

                  if (aggregateList[ProductID] === undefined) {
                    console.log('Ohaa', data.quantity)
                    aggregateList[ProductID] = parseInt(Quantity)
                  } else {
                    console.log('Ohaa2', aggregateList[ProductID])
                    aggregateList[ProductID] =
                      aggregateList[ProductID] + parseInt(Quantity)
                  }
                })
            })
        }
      })

      setTimeout(() => {
        console.log('MHMMM')
        console.log('LIST2! ', aggregateList)
        setAggregate(aggregateList)
      }, 3000)

      setTimeout(() => {
        handleDisplayAggregate(aggregateList)
      }, 5000)
    }
    fetchData()
  }

  const handleDisplayAggregate = list => {
    console.log('AGGREGATE!', list)

    const listCart = []

    const fetchAggregateData = async () => {
      for (var key in list) {
        const quantity = list[key]

        console.log(key, 'OHAA')

        await db
          .collection('Products')
          .doc(key)
          .get()
          .then(doc => {
            if (doc.exists) {
              const { Description } = doc.data()

              listCart.push({
                Description: Description,
                Qty: quantity,
              })

              console.log('PUSHED!')
            }
          })
          .then(() => {
            console.log('1', listCart)
            console.log('SET!')
          })
      }

      setAggregateItems(listCart)
      setDisplayAggregate(true)
      setLoading(false)
      console.log('LASTLY!')
      console.log(listCart)
    }

    fetchAggregateData()
  }

  const handleCloseCart = () => {
    setDisplayCart(false)
    setCartID('')
    setCartItems([])
    setGrandTotal('')
  }

  const handleCloseAggregate = () => {
    setDisplayAggregate(false)
    setLeaderID('')
    setLeaderName('')
  }

  const DialogAggregate = () => {
    return (
      <Dialog
        open={displayAggregate}
        onClose={handleCloseAggregate}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <H2>Aggregate : {LeaderName}</H2>
        </DialogTitle>
        <DialogContent>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow
                style={{
                  height: '5px',
                  width: '100%',
                  backgroundColor: '#d3d3d3',
                }}
              >
                <TableCell>
                  <b>
                    <h2>Description</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Qty</h2>
                  </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {aggregateItems.map(data => (
                <TableRow key={data.Description}>
                  <TableCell>{data.Description}</TableCell>
                  <TableCell>{data.Qty}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAggregate}>Cancel</Button>
        </DialogActions>
      </Dialog>
    )
  }

  const loader = () => {
    if (loading) {
      return (
        <CenteredDiv vertical>
          <Spinner large secondary style={MyLoader} />
        </CenteredDiv>
      )
    }
  }

  const DialogCart = () => {
    return (
      <Dialog
        open={displayCart}
        onClose={handleCloseCart}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">CartID: {cartID}</DialogTitle>

        <DialogContent>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow
                style={{
                  height: '5px',
                  width: '100%',
                  backgroundColor: '#d3d3d3',
                }}
              >
                <TableCell>
                  <b>
                    <h2>Description</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Qty</h2>
                  </b>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <b>
                    <h2>Price</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Total</h2>
                  </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cartItems.map(data => (
                <TableRow key={data.key}>
                  <TableCell>{data.desc}</TableCell>
                  <TableCell>{data.quantity}</TableCell>
                  <TableCell>X</TableCell>
                  <TableCell>{data.price}</TableCell>
                  <TableCell>
                    <b>{data.total}</b>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow key="row2">
                <TableCell colSpan={3}>
                  <h1>GrandTotal</h1>
                </TableCell>
                <TableCell colSpan={2}>
                  <h1>{grandTotal}</h1>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCart} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const pendingOrders = () => {
    return (
      <BodyWrapper>
        {DialogCart()}
        {DialogAggregate()}
        {loader()}
        <H1>
          <b>Completed Orders:</b> {pendingOrderList.length}
        </H1>

        <TableContainer component={Paper} style={{ maxHeight: 500 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow style={{ background: '#fe7c19' }}>
                <TableCell>
                  <P>Order ID</P>
                </TableCell>
                <TableCell>
                  <P>Leader ID</P>
                </TableCell>
                <TableCell>
                  <P>Leader Name</P>
                </TableCell>
                <TableCell>
                  <P>Customer ID</P>
                </TableCell>
                <TableCell>
                  <P>Customer Name</P>
                </TableCell>
                <TableCell>
                  <P>Phone Number</P>
                </TableCell>
                <TableCell>
                  <P>Cart ID</P>
                </TableCell>
                <TableCell>
                  <P>Delivery Address</P>
                </TableCell>
                <TableCell>
                  <P>Date</P>
                </TableCell>
                <TableCell>
                  <P>Status</P>
                </TableCell>
                <TableCell>
                  <P>Hold</P>
                </TableCell>
                <TableCell>
                  <P>Delivered?</P>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageLoading ? (
                <TableRow>
                  <TableCell colSpan={12} style={{ textAlign: 'center' }}>
                    <CircularProgress color="#fe7c19" size={40} />
                  </TableCell>
                </TableRow>
              ) : (
                pendingOrderList.map(data => (
                  <TableRow key={data.key}>
                    <TableCell>{data.key}</TableCell>
                    <TableCell>
                      <Button
                        onClick={e =>
                          handleFillLeaderAggregate(
                            e,
                            data.leaderID,
                            data.leaderName
                          )
                        }
                      >
                        {data.leaderID}
                      </Button>
                    </TableCell>
                    <TableCell>{data.leaderName}</TableCell>
                    <TableCell>{data.customerID}</TableCell>
                    <TableCell>{data.customerName}</TableCell>
                    <TableCell>{data.pNo}</TableCell>
                    <TableCell>
                      <Button
                        onClick={e =>
                          handleDisplayCart(e, data.cartID, data.Total)
                        }
                      >
                        {data.cartID}
                      </Button>
                    </TableCell>
                    <TableCell>{data.address}</TableCell>
                    <TableCell>{data.date}</TableCell>
                    <TableCell> {data.pod ? 'POD' : 'Paid'}</TableCell>
                    <TableCell>
                      <Switch
                        checked={data.hold}
                        onChange={e => handleHoldOrder(e, data.key)}
                        name={data.key}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={data.delivered}
                        onChange={e => handleChangeActivity(e)}
                        name={data.key}
                        color="primary"
                      />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </BodyWrapper>
    )
  }
  return pendingOrders()
}

export default PendingOrders
