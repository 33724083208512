import React, { useState, useContext, useEffect } from "react";
import { P, H1, BodyWrapper, Button } from "../components";
import TextField from '@material-ui/core/TextField';
import { UserContext } from "../contexts/userContext";
import firebase from "../firebase.js";

const VerifyOTP = (props) => {
    const [OTPCode, setOTPCode] = useState((Math.floor(1000 + Math.random() * 9000)).toString())
    const [pNo, setPNo] = useState("")
    const [inputCode, setinputCode] = useState("")
    const [codeError, setCodeError] = useState(false)
    const [returned, setReturned] = useState(false)
    // eslint-disable-next-line
    const { userState, userDispatch } = useContext(UserContext);
    const db = firebase.firestore();


    useEffect(() => {

        const checkPhoneNumber = (pNo) => {

            if(pNo.length === 10){

                const fullNo = "+254" + pNo.substring(1)

                setPNo(fullNo)

                fetchLeaderData(fullNo)

            }else if(pNo.length === 13){

                setPNo(pNo)
                fetchLeaderData(pNo)

            }

        }

        const fetchLeaderData = async (pNO) => {
            const data = await db.collection("CommunityLeaders").doc(props.location.state.leaderId)
    
            data.get().then((doc) => {
                console.log("DOC!")

                if(doc.exists){

                        const { OTP } = doc.data()

                        if( OTP === "0"){

                            SendOTP(pNO);
                            setReturned(false)

                        }else{

                            setOTPCode(OTP)
                            setReturned(true)
                        }

                }else{

                    console.log("Mismatch")
                    console.log(props.location.state.leaderId)

                }

            })
        }

        checkPhoneNumber(props.location.state.phoneNumber)

    }, [])

    const SendOTP = (to) => {

        const url = "https://api.tushop.io/api/v1/sms/OTP";
            
        fetch(url, 
            {
                method: 'POST',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    OTP: OTPCode,
                    // to: props.location.state.phoneNumber,
                    to: to,
                    CLID: null,
                })
            }
        ).then(response => {
                if (response.status === 201) {
                    return response.json()
                } else {
                    throw new Error('Something went wrong on api server!');
                }
        }).then(response => {
        
            console.debug(response);

            updateLeaderOTP()

        }).catch(error => {

            console.error(error);

        });
        
    }

    const updateLeaderOTP = async () => {
        const data = await db.collection("CommunityLeaders").doc(props.location.state.leaderId)
    
        data.update({
            "OTP" : OTPCode
        })
    }

    const handleChange = (e) => {
        setinputCode(e.target.value)
        setCodeError(false)
    }

    const handleSubmit = (e) => {
        if(inputCode.length === 4 && inputCode !== OTPCode.toString()){

            setCodeError(true)
    
        }else if(inputCode === OTPCode.toString()){

            console.log("Match! Redirect to Dashboard")
            userDispatch({ type: "name", payload: props.location.state.leaderName });
            userDispatch({ type: "pNo", payload: props.location.state.leaderPNO });
            userDispatch({ type: "userId", payload: props.location.state.leaderId });
    
        }else {
            setCodeError(false)
        }
    }

    const ResendOTP = () => {

        setOTPCode((Math.floor(1000 + Math.random() * 9000)).toString())

        SendOTP()

    }

    return (

        <BodyWrapper>
            <H1>Verify OTP</H1>

            {!returned?
            <div>
                <P>A four digit passcode has been sent to {pNo}</P>
                {/* eslint-disable-next-line */}
                <P><a onClick={e => ResendOTP()} >CLICK HERE</a> if you did not recieve an SMS</P>

                <P>Input the code below to proceed.</P>

                <P><i><b>NOTE:</b> Save the passcode for future logins</i></P>
            </div>
            :

            <div>

                <P>Input the 4 digit passcode</P>
                <P></P>
                {/* eslint-disable-next-line */}
                <P><a onClick={e => ResendOTP()} >CLICK HERE</a> if you lost your code</P>

            </div>
            
            }

            <TextField id="standard-basic"
                style={{width: "100%", marginTop: "20px", alignItems: "center"}}
                error={codeError}
                inputProps={{ maxLength: 4 }}
                helperText={codeError? 'Invalid Code!' : ' '}
                onChange={e => handleChange(e)}
                name="OTP"
                placeholder="OTP"
            />

            <Button onClick={handleSubmit}>SUBMIT</Button>
        </BodyWrapper>


    )

};

export default VerifyOTP;
