import React, { useState, useContext, useEffect } from 'react'
import { P, H1, H2, Button, BodyWrapper } from '../components'
import { UserContext } from '../contexts/userContext'
import firebase from '../firebase.js'
import 'firebase/firestore'
import FeedbackIcon from '@material-ui/icons/Feedback'
import Fab from '@material-ui/core/Fab'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'

const Dashboard = () => {
  const [pendingOrderList, setPendingOrderList] = useState([])
  const [completedOrderList, setCompletedOrderList] = useState([])
  const [customerList, setCustomerList] = useState([])
  const [displayRegisterCL, setDisplayRegisterCL] = useState(false)
  const [displayThankyouNote, setDisplayThankyouNote] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [rate, setRate] = useState('0')
  const [CName, setCName] = useState('')
  const [CLRequestID, setCLRequestID] = useState(
    'CLR' + Math.floor(100000000 + Math.random() * 900000000)
  )
  const [CEmail, setCEmail] = useState('')
  const [CPNo, setCPNo] = useState('')
  const [CAddress, setCAddress] = useState('')
  const [dateCreated, setDateCreated] = useState('')
  const { userState } = useContext(UserContext)
  const db = firebase.firestore()

  const floatingButton = {
    margin: 0,
    top: 80,
    right: 20,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed',
    zIndex: 1,
  }

  useEffect(() => {
    const fetchOrderData = async () => {
      const data = await db
        .collection('Orders')
        .orderBy('DateOfOrder', 'asc')
        .get()

      const pendingList = []

      const completedList = []

      data.forEach(doc => {
        const {
          CustomerID,
          CustomerName,
          CartID,
          CustomerPNo,
          DeliveryAddress,
          LeaderID,
          LeaderName,
          TotalPrice,
          DateOfOrder,
          Delivered,
          POD,
          Return,
        } = doc.data()

        if (!Delivered) {
          pendingList.push({
            key: doc.id,
            customerID: CustomerID,
            customerName: CustomerName,
            cartID: CartID,
            pNo: CustomerPNo,
            address: DeliveryAddress,
            leaderID: LeaderID,
            leaderName: LeaderName,
            Total: TotalPrice,
            date: DateOfOrder,
            delivered: Delivered,
            pod: POD,
            return: Return,
          })
        } else if (Delivered) {
          completedList.push({
            key: doc.id,
            customerID: CustomerID,
            customerName: CustomerName,
            cartID: CartID,
            pNo: CustomerPNo,
            address: DeliveryAddress,
            leaderID: LeaderID,
            leaderName: LeaderName,
            Total: TotalPrice,
            date: DateOfOrder,
            delivered: Delivered,
            pod: POD,
            return: Return,
          })
        }
      })

      setPendingOrderList(pendingList)
      setCompletedOrderList(completedList)
    }

    fetchOrderData()
  }, [])

  const dashboard = () => {
    return (
      <BodyWrapper>
        <H1>Welcome</H1>
        <P>
          <b>Pending Orders:</b> {pendingOrderList.length}
        </P>
        <P>
          <b>Completed Orders:</b> {completedOrderList.length}
        </P>
      </BodyWrapper>
    )
  }
  return dashboard()
}

export default Dashboard
